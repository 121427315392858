import { isValid, format } from 'date-fns';

export type FormattedDate = `${string}-${string}-${string}`;

export const isValidDate = (date: Date | string) => {
  const newDate = typeof date === 'string' ? new Date(date) : date;

  return newDate instanceof Date && !Number.isNaN(newDate.getTime());
};

export const daysRemaining = (date: string | null | undefined): number | null => {
  if (!date) {
    return null;
  }

  const endDate = new Date(date);
  const currentDate = new Date();
  const oneDayInMs = 24 * 60 * 60 * 1000; // number of milliseconds in one day
  const timeDiffInMs = endDate.getTime() - currentDate.getTime(); // difference between the two dates in milliseconds
  const timeDiffInDays = Math.floor(timeDiffInMs / oneDayInMs); // difference in days rounded down

  return timeDiffInDays;
};

export const isValidFormattedDate = (formattedDate: string) => {
  const meetingRegex = /\d{4}-([0-1]\d)-([0-3]\d)/;
  const isFormattedDate = meetingRegex.test(formattedDate);

  if (!isFormattedDate) {
    return false;
  }

  const day = +formattedDate.split('-')[2];
  const date = new Date(formattedDate);

  return date instanceof Date && !Number.isNaN(date.getTime()) && date.getDate() === day;
};

export const adaptDateToSearchParams = (date: Date): FormattedDate => {
  const usedDate = isValidDate(date) ? date : new Date();

  const day = usedDate.getDate().toLocaleString('fr-FR', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  const year = usedDate.getFullYear();
  const month = (usedDate.getMonth() + 1).toLocaleString('fr-FR', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });

  return `${year}-${month}-${day}`;
};

export const formatDateToTwoDigits = (date: Date | string, locale: string): string => {
  const formattedDate = new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
  }).format(typeof date === 'string' ? new Date(date) : date);

  return formattedDate;
};

export const formatDateWithDay = (date: Date | string, locale: string): string => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
  };
  let formattedDate = new Intl.DateTimeFormat(locale, dateOptions).format(
    typeof date === 'string' ? new Date(date) : date
  );
  formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  return formattedDate;
};

export const getNextThreeDates = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const dayAfterTomorrow = new Date(tomorrow);
  dayAfterTomorrow.setDate(tomorrow.getDate() + 1);

  return {
    today: adaptDateToSearchParams(today),
    tomorrow: adaptDateToSearchParams(tomorrow),
    dayAfterTomorrow: adaptDateToSearchParams(dayAfterTomorrow),
  };
};

export const dateConverter = (date: string, locale: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat(locale, options).format(new Date(date));
};

export const findIndexByDate = (startDate: FormattedDate) => {
  const startDateTime = new Date(startDate).getTime();
  const newDate = adaptDateToSearchParams(new Date());
  const newDateTime = new Date(newDate).getTime();

  return (startDateTime - newDateTime) / 86400000;
};

export const formatDate = (dateStr: string) => {
  if (isValidFormattedDate(dateStr)) {
    return dateStr;
  }

  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);

  const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));

  const formatter = new Intl.DateTimeFormat('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC',
  });

  return formatter.format(date);
};

export const removeDateHyphens = (date: string): string => {
  return date.replace(/-/g, '');
};

export const formatAndValidateDate = (date: Date, dateFormat = 'yyyyMMdd') => {
  const parsedDate = new Date(date);

  if (!isValid(parsedDate)) {
    return null;
  }

  return format(parsedDate, dateFormat);
};

export const replaceStartDate = (route: string, startDate?: string) => {
  const today = removeDateHyphens(new Date().toLocaleDateString('fr-CA'));
  const dateToUse = startDate || today;

  return route.replace(':startDate', dateToUse);
};
