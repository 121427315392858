import type { ApiRace } from '../../types/apiRace';

const isValidTime = (raceTime: string) => {
  // Should match hh:mm:ss exactly (e.g 17:32:23)
  return /^(?:2[0-3]|[01]?\d):[0-5]\d:[0-5]\d$/.test(raceTime);
};

// When we have a date (Race['officialResultTime'] or Race['actualStartTime']) formatted like this 2022-11-09 17:32:23, we want to retrieve only the second part (real result time only)
const adaptRaceTime = (raceTime: ApiRace['officialResultTime']): string | null => {
  if (!raceTime) {
    return null;
  }

  const [, time] = raceTime.split(' ');

  if (!time) {
    return null;
  }

  return isValidTime(time) ? time : null;
};

export { adaptRaceTime };
