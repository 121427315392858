import { filterUnknownBetCodes } from './races/betsOffering';
import { adaptRaceTime } from './races/raceTime';
import { adaptRanking } from './races/ranking';

import type { DisciplineCode, Race } from '../types';
import type { ApiRace } from '../types/apiRace';

const adaptRace = (race: ApiRace): Race => {
  const {
    ranking,
    discipline,
    actualStartTime,
    officialResultTime,
    startingMode,
    turn,
    raceCategory,
    raceType,
    trackType,
    course,
    trackCondition,
    raceStatus,
    racecourse,
    betsOffering,
    raceConditions,
    ...raceOtherProps
  } = race;

  return {
    ...raceOtherProps,
    ranking: adaptRanking(ranking),
    raceStatus: raceStatus?.code ?? null,
    actualStartTime: adaptRaceTime(actualStartTime),
    officialResultTime: adaptRaceTime(officialResultTime),
    startingMode: startingMode?.label ?? null,
    turn: turn?.label ?? null,
    raceCategory: raceCategory?.label ?? null,
    raceType: raceType?.label ?? null,
    trackType: trackType?.label ?? null,
    course: course?.label ?? null,
    trackCondition,
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    discipline: (discipline?.code as DisciplineCode) ?? null,
    racecourse: racecourse.code,
    betsOffering: filterUnknownBetCodes(betsOffering),
    raceConditions: raceConditions
      ? {
          fullConditions: raceConditions.fullConditions ?? null,
          breedConditions: raceConditions.breedConditions ?? null,
          genderConditions: raceConditions.genderConditions ?? null,
          ageConditions: raceConditions.ageConditions ?? null,
          riderConditions: raceConditions.riderConditions ?? null,
        }
      : null,
  };
};

const adaptRaces = (races: ApiRace[]): Race[] => races.map(adaptRace);

export { adaptRaces, adaptRace };
