const ROUTES = {
  HOME: '/',
  RACECARDS: '/racecards/:startDate',
  MEETINGS: ':racecourseCode',
  RACES: ':racecourseCode/:raceNumber',
  QUINTE: '/quinte/:startDate',
  NOT_FOUND: '/404',
  LOGIN: '/login',
  ACCOUNT: '/account',
  CGU_VALIDATION: '/cgu-validation',
  NON_RUNNERS: '/non-runners/:startDate',
  RACING_EVENTS: '/racing-events/:startDate',
};

export { ROUTES };
