import { replaceStartDate } from '@utils/date';

import { ROUTES } from './routes';

export const {
  API_GW_URL: BASE_URL,
  S3_ASSETS_URL,
  B2B_PMU_URL: AUTHENTICATION_PMU,
  B2B_COGNITO_URL: AUTHENTICATION_COGNITO,
  API_CATALOG_LINK_URL,
  CGU_LINK_URL,
  PRIVACY_POLICY_LINK_URL,
  FEEDBACK_URL,
  OLD_INFOCENTRE_URL,
} = window.config;

const hdePrefix = '/hde/v1';
const b2bPrefix = '/b2b-manager';

export const SCHEDULE_MEETINGS = `${hdePrefix}/schedule/meetings`;
export const SCHEDULE_RACES = `${hdePrefix}/schedule/races`;
export const EVENTS = `${hdePrefix}/events`;
export const SCHEDULE_RUNNERS = 'runners';
export const ODDS_FINAL = `${hdePrefix}/final-dividends`;
export const ODDS_BREAKDOWN = `${hdePrefix}/odds/breakdown`;
export const CONTACTS_USER = `${b2bPrefix}/contacts/user`;
export const CONTACTS_LOGOUT = `${b2bPrefix}/contacts/logout`;
export const CONTACTS_VALIDATE_CGU = `${b2bPrefix}/contacts/validate-cgu`;
export const REFRESH_TOKEN = `${b2bPrefix}/contacts/refresh-token`;
export const PARTNERS = `${b2bPrefix}/partners`;
export const PARTNERS_ISSUE_CERTIFICATE = `${b2bPrefix}/partners/issue-certificate`;
export const PARTNERS_DOWNLOAD_CERTIFICATE = `${b2bPrefix}/partners/download-certificate`;

const getRaceUrl = ({
  startDate,
  racecourseCode,
  raceNumber,
}: {
  startDate: string;
  racecourseCode?: string;
  raceNumber: number;
}) => `${replaceStartDate(ROUTES.RACECARDS, startDate)}/${racecourseCode}/${raceNumber}`;

const getRacecourseMeetingUrl = ({
  startDate,
  racecourseCode,
}: {
  startDate: string;
  racecourseCode?: string;
}) => {
  return `${replaceStartDate(ROUTES.RACECARDS, startDate)}/${racecourseCode}`;
};

const getRacescardsUrl = ({ startDate }: { startDate: string }) => {
  return replaceStartDate(ROUTES.RACECARDS, startDate);
};

const getQuintePlusUrl = ({ startDate }: { startDate: string }) => {
  return replaceStartDate(ROUTES.QUINTE, startDate);
};

const getNonRunnersUrl = ({ startDate }: { startDate: string }) => {
  return replaceStartDate(ROUTES.NON_RUNNERS, startDate);
};

const getRacingEventsUrl = ({ startDate }: { startDate: string }) => {
  return replaceStartDate(ROUTES.RACING_EVENTS, startDate);
};

export {
  getRacecourseMeetingUrl,
  getRacescardsUrl,
  getRaceUrl,
  getQuintePlusUrl,
  getNonRunnersUrl,
  getRacingEventsUrl,
};
